<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { get, throttle } from 'lodash';
import anime from 'animejs/lib/anime.es.js';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      menuVisible: false
    };
  },
  computed: {
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    }
  },
  methods: {
    getTarget: Fandom.getTarget,
    mayAnchorTo(item, event) {
      const path = window.location.pathname;
      if (item.url.url.startsWith("#") || item.url.url.startsWith(`${path}#`)) {
        this.toggleMenu();
        Vue.nextTick(() => {
          const position = $(item.url.url.replace(path, "")).offset().top - $("#main-navbar").outerHeight();
          $("html, body").animate({ scrollTop: position }, 0, "linear");
        })

        event.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    onEnter(el) {
      anime({
        targets: el,
        opacity: [0, 1],
        easing: 'linear',
        duration: 350,
        delay: el.dataset.index * 80,
      });
    },
    onLeave() {
      $(this.$refs.navbar).removeClass('bg-color-transition');
    }
  }
};
</script>

<template>
  <nav id="main-navbar" ref="navbar" class="fixed-top text-white overflow-auto bg-color-transition" :class="menuVisible ? 'bg-primary' : 'bg-white'" navbar-component>
    <div class="container" :class="{'min-vh-100': menuVisible}">
      <div class="navbar-content position-relative d-flex align-items-center justify-content-between">
        <a :href="applyContextToUrl('/')">
          <h1 class="mb-0">
            <img :src="menuVisible ? content.logo_light.url : content.logo_dark.url" class="logo" :alt="logoAlt"/>
          </h1>
        </a>
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-icon cursor-pointer fa-2xl" @click="toggleMenu()">
            <i v-if="!menuVisible" class="fa-regular fa-bars text-dark"></i>
            <i v-else class="fa-regular fa-xmark text-white"></i>
          </div>
        </div>
      </div>
      <div v-if="content.items" v-show="menuVisible" class="py-5" menu-component>
        <TransitionGroup @enter="onEnter" @leave="onLeave">
          <a
            v-show="menuVisible"
            v-for="item, index in content.items"
            :href="item.url.url"
            @click="mayAnchorTo(item, $event)"
            :target="getTarget(item.url)"
            :key="index"
            :data-index="index"
            class="menu-link"
          >
            <h2 class="display-4">{{ item.url.title }}</h2>
          </a>
        </TransitionGroup>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>

.bg-color-transition {
  transition: background-color 0.3s;
}

[navbar-component] {
  max-height: 100vh;

  .logo {
    width: auto;
    height: calc(#{$header-height} - 1.5rem);

    @include media-breakpoint-down(sm) {
      height: calc(#{$header-height-sm} - 1.5rem);
    }
  }

  .navbar-content {
    z-index: 1040;
    min-height: $header-height;

    @include media-breakpoint-down(sm) {
      min-height: $header-height-sm;
    }
  }

  .menu-icon {
    user-select: none;
  }
}

[menu-component] {
  .menu-link {
    color: white;
    text-decoration: none !important;

    &:hover {
      opacity: 0.8 !important;
    }
  }
}

</style>