<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function() {
    return {
      paddingYDefault: 5
    };
  },
  methods: {
    getImage(item) {
      return item.image?.url;
    },
    getButtonClass(item) {
      return item.button_class || (this.content.theme == "dark" ? "btn-light" : "btn-dark");
    },
  },
  computed: {
    rounded() {
      return this.content.image_rounded === undefined ? true : this.content.image_rounded;
    },
    skin() {
      return this.content.skin || '3';
    },
    skinSet() {
      const keypointClasses = [];
      const containerClasses = [];
      switch(this.skin) {
        case '2':
          keypointClasses.push('col-md-6 col-12');
          containerClasses.push('col-lg-8 col-md-10 col-12');
          break;
        case '3':
          keypointClasses.push('col-lg-4 col-md-6 col-12');
          containerClasses.push('col-lg-8 col-md-10 col-12');
          break;
        case '4':
          keypointClasses.push('col-lg-3 col-md-6 col-12');
          containerClasses.push('col-md-10 col-12');
          break;
      }
      return {
        keypoint: keypointClasses,
        container: containerClasses
      };
    }
  }
};
</script>

<template>
<div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" class="themed-item" :class="contentType" :style="theme" list-keypoints-component>
  <div :class="[skinSet.container, paddingY]" class="container" >
    <div class="row justify-content-center">
      <div :class="skinSet.keypoint" class="w-100 my-3 px-5 text-center" v-for="item in content.items" :key="`list-${item.$id}`" v-if="item">
        <div v-if="getImage(item)" class="mx-auto mb-4 keypoint-image" :style="{'width': content.image_width}">
          <div class="ar-1by1 h-100 w-100 bg-cover-center" :class="{'rounded-circle': rounded}" :style="{ 'background-image': `url(${item.image.url})`}" ></div>           
        </div>
        <p v-if="item.title" :class="content.title_class || 'h4'">{{item.title}}</p>
        <div v-if="item.description" v-html="item.description"></div>
         <a
          v-if="item.button"
          :class="getButtonClass(item)"
          class="btn shadow-none m-2 text-uppercase"
          :href="item.button.url"
          :target="getTarget(item.button)">{{item.button.title}}</a>
      </div>          
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
[list-keypoints-component] {
  .keypoint-image {
    width: 10rem;
  }

  .link-primary .fa-arrow-right {
    color: var(--primary);
  }
}
</style>
