<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {};
  },
  mounted() {
    Vue.nextTick(() => {
      this.mountCarousel();
    });
  },
  methods: {
    mountCarousel() {
      const config =  {
        draggable: "> 1",
        prevNextButtons: false,
        contain: true,
        pageDots: false,
        cellAlign: 'left',
        wrapAround: true,
        groupCells: false,
        imagesLoaded: true,
        autoPlay: 3000,
        arrowShape: "M47.89,1,.78,48.11a2.68,2.68,0,0,0,0,3.78L47.89,99a2.67,2.67,0,0,0,3.79,0l1.58-1.57a2.67,2.67,0,0,0,0-3.79L13.42,53.79h83.9A2.67,2.67,0,0,0,100,51.12V48.88a2.67,2.67,0,0,0-2.68-2.67H13.42L53.26,6.36a2.67,2.67,0,0,0,0-3.79L51.68,1A2.67,2.67,0,0,0,47.89,1Z"
      }

      this.slider = new Flickity(this.$refs["flickity-carousel"], config);
      this.slider.on("dragStart", this.onDragStart());
      this.slider.on("dragEnd", this.onDragEnd());
    },
    onDragStart() {
      $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'none');
    },
    onDragEnd() {
      $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'all');
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :id="anchorId" list-carousel-component>
    <div ref="flickity-carousel" class="flickity-carousel">
      <div v-for="(slide, index) in content.list" :key="`carousel-slide-${index}`" class="flickity-carousel-item">
        <img :src="slide.image.url">
      </div>
    </div>
  </div>
</template>

<style lang="scss">

[list-carousel-component] {
  .flickity-button {
    width: 70px;
    height: 70px;
    color: white;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
    }
  }

  .flickity-carousel-item {
    height: 30rem;

    img {
      height: 100%;
      width: auto;
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-carousel-item {
      height: 100vw;
    }
  }
}

</style>