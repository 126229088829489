<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget
  },
  computed: {
    infoCount() {
      let count = 0;
      for (const i in this.content.shops) {
        const info = this.content.shops[i].info;
        if (info.length > count) {
          count = info.length;
        };
      }
      return count;
    }
  },
  methods: {
    getInfo(shop, index) {
      return shop.info[index - 1] || {};
    }
  }
};
</script>

<template>
  <div footer-component class="bg-primary">
    <div class="container text-white py-3">
      <div class="row">
        <div class="col-12 col-lg-6 py-4">
          <img v-if="content.logo" :src="content.logo.url" class="logo mb-5"/>
          <p v-if="content.title" class="text-uppercase">{{ content.title }}</p>
          <div v-if="content.description" class="description small" v-html="content.description"></div>
        </div>
        <div class="col-12 d-md-none py-4">
          <div class="grid">
            <div v-for="shop in content.shops" class="pt-4 info-border">
              <div v-for="info in shop.info">
                <p v-if="info.title" class="text-uppercase">{{ info.title }}</p>
                <div v-if="info.description" class="description small" v-html="info.description"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col d-none d-md-block py-4">
          <div class="grid grid-desktop">
            <template v-for="index in infoCount">
              <div v-if="getInfo(shop, index)" v-for="shop in content.shops" :class="{'pt-4 info-border': index == 1}">
                <p v-if="getInfo(shop, index).title" class="text-uppercase">{{ getInfo(shop, index).title }}</p>
                <div v-if="getInfo(shop, index).description" class="description small" v-html="getInfo(shop, index).description"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center py-4">
        <div v-for="item in content.navbar_bottom" class="mr-3">
          <a v-if="item.url" :href="item.url.url" :target="getTarget(item.url)" class="text-white">
            <i v-if="item.icon" :class="item.icon" class="fa-2xl" :title="item.url.title"></i>
            <span v-else-if="item.url.title" class="small">{{ item.url.title }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[footer-component] {
  .logo {
    width: 10rem;
    height: auto;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .grid-desktop {
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
  }

  .info-border {
    border-top: 2px solid white;
  }

  .description p:last-child {
    margin-bottom: 0;
  }
}

</style>
