<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    rankingConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    isUserRewardType() {
      return this.rankingConfig.type == 'user_reward';
    }
  },
  methods: {
    getContentThumb(contentCache) {
      const thumbnail = contentCache.thumbnail?.url;
      return thumbnail ? thumbnail : contentCache.image?.url;
    }
  }
};
</script>

<template>
  <div class="border-bottom p-3" :class="item.user.is_current_user ? 'bg-light' : 'bg-white'" ranking-item-component>
    <div class="row align-items-center">
      <div class="col-ranking-position col-auto d-flex flex-column align-items-center">
        <div class="h5 mb-0">#{{ item.position }}</div>
        <div v-if="item.position_change != 0" class="mt-2">
          <div v-if="item.position_change == null" class="badge badge-pill badge-new">New</div>
          <div v-else-if="item.position_change > 0" class="badge badge-pill badge-up">
            <i class="fa-regular fa-arrow-up"></i>
            <span>{{ item.position_change }}</span>
          </div>
          <div v-else-if="item.position_change < 0" class="badge badge-pill badge-down">
            <i class="fa-regular fa-arrow-down"></i>
            <span>{{ Math.abs(item.position_change) }}</span>
          </div>
        </div>
      </div>
      <div :class="isUserRewardType ? 'col-user-image' : 'col-content-image'" class="col-auto d-none d-md-flex">
        <div v-if="isUserRewardType" class="user-avatar d-flex align-items-center justify-content-center bg-light rounded-circle overflow-hidden">
          <img v-if="item.user.avatar" :src="item.user.avatar" class="w-100 h-100 object-fit-cover" />
          <i v-else class="fa-solid fa-user fa-xl"></i>
        </div>
        <img v-else :src="getContentThumb(item.content_cache)" class="content-image object-fit-cover w-100 bg-light" />
      </div>
      <div class="col-title col">
        <div v-if="!isUserRewardType" class="d-flex align-items-center small mb-2 mb-md-3">
          <div class="user-avatar d-flex align-items-center justify-content-center bg-light rounded-circle overflow-hidden mr-2">
            <img v-if="item.user.avatar" :src="item.user.avatar" class="w-100 h-100 object-fit-cover" />
            <i v-else class="fa-solid fa-user"></i>
          </div>
          <div>{{ item.user.username }}</div>
        </div>
        <div class="h5 mb-0">{{ isUserRewardType ? item.user.username : item.content_cache.title }}</div>
      </div>
      <div class="col-value col-auto text-warning">
        <div class="badge badge-value p-2">
          <span class="font-weight-bold">
            <span>{{ item.value }}</span>
            <span class="d-none d-md-inline text-capitalize">{{ rankingConfig.value }}s</span>
          </span>
          <i class="fa-solid fa-lg fa-star ml-1"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-ranking-component] {

  .object-fit-cover {
    object-fit: cover;
  }

  .col-ranking-position {
    width: 5.5rem;

    @include media-breakpoint-up(md) {
      width: 7rem;
    }
  }

  .col-content-image {
    width: 10rem;

    .content-image {
      aspect-ratio: 4/3;
    }
  }

  .col-user-image {
    .user-avatar {
      height: 4rem;
    }
  }

  .col-title {
    .user-avatar {
      height: 1.75rem;
    }
  }

  .col-value {
    width: 6rem;

    @include media-breakpoint-up(md) {
      width: 12rem;
    }
  }

  .user-avatar {
    aspect-ratio: 1/1;
  }

  .badge, .small {
    font-size: 0.875rem !important;
  }

  .badge {
    &-up {
      color: $success;
      background-color: rgba($success, 0.2);
    }

    &-down {
      color: $danger;
      background-color: rgba($danger, 0.2);
    }

    &-new {
      color: $info;
      background-color: rgba($info, 0.2);
    }

    &-value {
      color: $warning;
      background-color: rgba($warning, 0.2);
    }
  }
}
</style>
