<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [ fandomBasicMixin, fandomExtendedMixin ],
  data: function() {
    return { };
  },
  mounted() {
    this.content.accordion.forEach((item) => {
      Vue.set(item, "collapsed", true);
    });
  },
  methods:{
    toggleItem(index) {
      Vue.set(this.content.accordion[index], "collapsed", !this.content.accordion[index]["collapsed"]);
    }
  },
  computed: {
    questionColor() {
      return {
        "color": this.content.question_color
      }
    },
    answerColor() {
      return {
        "color": this.content.answer_color
      }
    },
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image")
      };
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="anchorId" v-if="isContentVisibleToUser()" :class="contentType" class="py-5 themed-item" :style="[theme, rootStyle]" stripe-accordion-component>
    <div class="container bg-contain">
      <text-section :content="content" :opt-container-content="containerContent" class="mb-4"></text-section>
      <div class="separator w-100 bg-dark"></div>
      <div v-for="(item, index) in content.accordion" :key="index" :ref="'accordion-'+index">
        <a
          :href="`#item-${index}-${content.$id}`"
          :aria-controls="`item-${index}-${content.$id}`"
          class="deco-none w-100 collapsed accordion"
          data-toggle="collapse"
          @click="toggleItem(index)">
          <div class="d-flex my-4 align-items-center">
            <div v-if="item.icon"  :style="{ 'background-image': 'url(' + item.icon.url + ')' }" class="image-container mr-4"></div>
            <div :style="questionColor" class="h1 flex-grow-1 mb-0 mr-4">{{item.title}}</div>
            <div class="icon-container d-flex align-items-center justify-content-center fa-regular fa-2xl"></div>
          </div>
        </a>
        <div class="collapse" :id="`item-${index}-${content.$id}`" :data-parent="`#${anchorId}`">
          <p :style="answerColor" class="h5 mb-4" v-html="item.description"></p>
        </div>
        <div class="separator w-100 bg-dark"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[stripe-accordion-component] {
  background-position: top;
  background-size: contain;

  .icon-container {
    width: 2rem;
    height: 2rem;
  }

  .image-container {
    height: 5rem;
    width: 5rem;
    flex-shrink: 0;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-down(xs) {
    .image-container {
      height: 4rem;
      width: 4rem;
    }
  }

  a.accordion.collapsed .icon-container::before {
    font-family: "Font Awesome 6 Pro";
    content: "\2b";
  }

  a.accordion .icon-container::before {
    font-family: "Font Awesome 6 Pro";
    content: "\f00d";
  }

  .separator {
    height: 2px;
    opacity: 0.2;
  }
}

</style>
